export default () => ({
    userInformation: {
        jwtToken: null,
    },

    selectedTab: null,
    selectedWalletTab: null,
    wallets: null,
    notifications: null,


    serverIpAddr: process.env.VUE_APP_IP || '',

})